import React from 'react';
import { useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import './classPageInfo.css'
import ImageGallery from "react-image-gallery";
import PopUp from '../popUp/popUp';
import TimeButtonsItem from '../timeButtonsItem/timeButtonsItem';
import DateButtonsItem from '../dateButtonsItem/dateButtonsItem';
import { Helmet } from 'react-helmet';
import SpecificInfoItem from '../specificInfoItem/specificInfoItem';
import EventsLoader from '../loader/bigLoader';
import loadingButton from "../loader/buttonLoader.svg"
import backButtonSvg from "../../images/icons/back.svg"

import square from "../../images/icons/square.svg"
import people from "../../images/icons/people.svg"
import calendar from "../../images/other/calendar.webp"

import dataObj from '../classroomList/data.js';


const ClassPageInfo = () => {
    const {id} = useParams();

    const navigate = useNavigate();

    const [roomAllData, setRoomAllData] = useState({})
    const [datePicked, setDatePicked] = useState([])
    const [timePicked, setTimePicked] = useState([])
    const [description, setDescription] = useState()
    const [popUpShow, setPopUpShow] = useState(false)
    const [loadingButtonShow, setLoadingButtonShow] = useState(false)

    const orderButton = useRef(null)
    const data = dataObj.classrooms;

    useEffect(() => {
        console.log(data);
        
        const foundObject = data.find(obj => obj.id === id);
        console.log(foundObject);
        

        setRoomAllData(foundObject)
    }, [])    

    const popUpFunc = () => {
        if (popUpShow)
        return (
            <>
            <PopUp 
            setDatePicked={setDatePicked}
            setTimePicked={setTimePicked}
            show={popUpShow}
            setPopUpShow={setPopUpShow}
            datePicked={datePicked}
            timePicked={timePicked}
            />
            </>
        )
    }


    const clickOrder = () => {
        setLoadingButtonShow(true)
        orderButton.current.classList.add('addPadding');
        orderButton.current.setAttribute('disabled', '');
        const orderTimer = setTimeout(() => {
            const newDate = roomAllData.available_dates ? roomAllData.available_dates.filter(item => !datePicked.includes(item)) : [];
            const newTime = roomAllData.available_times ? roomAllData.available_times.filter(item => !timePicked.includes(item)) : [];
    
            setRoomAllData({ ...roomAllData, available_dates: newDate, available_times: newTime })
            const changePop = () => setPopUpShow(true);
            changePop()

            const removeButtonLoading = setTimeout(() => {
                const removeLoading = () => setLoadingButtonShow(false);
                orderButton.current.classList.remove('addPadding');
                orderButton.current.removeAttribute('disabled');

                removeLoading()
            }, 1200)
           
        }, 1200)
    }

      
    const contentFunc = () => {                
        if (Object.keys(roomAllData).length === 0) {
            return <EventsLoader/>
        }

        let imagesArr = []
        roomAllData.images.forEach(element => {
        imagesArr.push({original: element, thumbnail: element})
        });

        const dateButtons = () => {
            return (roomAllData.available_dates.map((item, index) => {
                return (
                    <DateButtonsItem 
                        key={index} 
                        index={index} 
                        date={item}
                        datePicked={datePicked}
                        setDatePicked={setDatePicked}/>
                )
            }))
        }

        const timeButtons = () => {
            return (
                roomAllData.available_times.map((item, index) => {
                    return <TimeButtonsItem 
                                key={index} 
                                timePicked={timePicked}  
                                time={item}
                                setTimePicked={setTimePicked}/>
                })
            )
        }

        const specificInfo = () => {
            return (
                roomAllData.specifications.map((item, index) => {
                    return <SpecificInfoItem key={index} name={item}/>
                })
            )
        }

        return (
            <> 
      
            <div onClick={ () => navigate(-1)} className="backButtonContainer">
                <div className="imgBackContainer">
                    <img src={backButtonSvg} alt="" />
                </div>
                <h6>Назад</h6>
                
            </div>


            <h2>{roomAllData.name}</h2>
                <div className="wrapperPics">

                    <div className="ImagesSlider">
                        <ImageGallery
                        items={imagesArr}
                        additionalClass={'huge-slider'}
                        showThumbnails={true}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showBullets={true}
                        />
                    </div>

                    <div className="secondWrapperPics">
                        <ul className="containerSpecific">
                            <li className="specific">
                                <img src={square} alt="" />
                                <p>{`площадь ${roomAllData.square} м²`}</p>
                            </li>

                            <li className="specific">
                                <img src={people} alt="" />
                                <p>{`до ${roomAllData.seats} человек`}</p>
                            </li>
                            {specificInfo()}
                        </ul>

                        <div className="orderContainer">
                            <img className='calendarPic' src={calendar} alt="" />
                            <p className='orderdata'>{`${datePicked.length !== 0 ? `${datePicked[0]} декабря, 2023` : 'Дата не выбрана'} `}</p>
                            <p className='orderdata'>{`${timePicked.length !== 0 ? `Время: ${timePicked.join(', ')}` : 'Время не выбрано'} `}</p>
                            <button 
                                onClick={ () => clickOrder() } disabled={!(datePicked.length && timePicked.length)} 
                                className='orderButton' ref={orderButton}>
                                    <div className="buttonContainer">
                                        {loadingButtonShow ? <img className="buttonLoader" src={loadingButton} alt="" /> : undefined}
                                        <p>Забронировать</p>
                                    </div></button>
                        </div>

                    </div>

                </div>


                <div className="dateWholeContainer">

                <h3>Дата</h3>
                    <ul className="date-container">
                        {dateButtons()}
                    </ul>
                </div>

                <div className="timeWholeContainer">
                <h3>Время</h3>
                        <div className="timeContainer">
                            {timeButtons()}
                        </div>
                </div>

                <div className="descriptionWholeContainer">
                    <h3>Описание мероприятия</h3>
                    <textarea value={description} onChange={ (e) => setDescription(e.target.value)} name="" id="description" cols="30" rows="10"></textarea>
                </div>



                <div className="orderContainer Last">
                            <img className='calendarPic' src={calendar} alt="" />
                            <p className='orderdata'>{`${datePicked.length !== 0 ? `${datePicked[0]} декабря, 2023` : 'Дата не выбрана'} `}</p>
                            <p className='orderdata'>{`${timePicked.length !== 0 ? `Время: ${timePicked.join(', ')}` : 'Время не выбрано'} `}</p>
                            <button 
                                onClick={ () => clickOrder() } disabled={!(datePicked.length && timePicked.length)} 
                                className='orderButton' ref={orderButton}>
                                    <div className="buttonContainer">
                                        {loadingButtonShow ? <img className="buttonLoader" src={loadingButton} alt="" /> : undefined}
                                        <p>Забронировать</p>
                                    </div></button>
                        </div>

                
            </>
        )

        
    }
    const isPopUp = popUpFunc()
    const elements = contentFunc() 
    return (
        <>
        <Helmet>
            <title>{roomAllData.name}</title>
        </Helmet>
        {isPopUp}
        
        <div className="detailContainer">
            {elements}        
        </div>
        </>  
    )
}

export default ClassPageInfo