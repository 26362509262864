import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import FilterContainer from '../filter/Filter.js';
import ClassroomList from '../classroomList/classroomList.js';

function MainPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const loginStorage = localStorage.getItem("login");
    // setLogin(Boolean(loginStorage));
    if (loginStorage === "false") {
      navigate('/');
    }

  }, []);



    return (
      <>
        <FilterContainer />
        <ClassroomList />
      </>
    );
  } 

export default MainPage;
