import './classroomListItem.css'
import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";



import squarePic from "../../images/icons/square.svg"
import people from "../../images/icons/people.svg"
import ImageGallery from "react-image-gallery";

const ClassroomListItem = ({name, square, images, seats, id}) => {
    const navigate = useNavigate();

    let imagesArr = []
    images.forEach(element => {
      imagesArr.push({original: element, thumbnail: element})
    });

    const redirectFunc = (e) => {
        console.log(e.target);
        navigate(`${id}`)
        // navigate(-1)
        
    }


    return (

        <div className="roomItemContainer">
            <div className="roomItemImg">
            <ImageGallery
                    onClick={ (e) => redirectFunc(e) }      
                    items={imagesArr}
                    additionalClass={'small-slider'}
                    showThumbnails={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={true}
                    />
            </div>
                
            <div onClick={ (e) => redirectFunc(e) } className="roomItemInfo">
                <h4>{name}</h4>

                <div className="tagsInfoContainer">

                    <div className="tagInfo">
                        <img src={squarePic} alt="" />
                        <p className='roomItemInfoP'>{`${square}м`}</p>
                    </div>

                    <div className="tagInfo">
                        <img src={people} alt="" />
                        <p className='roomItemInfoP'>{`${seats} мест`}</p>
                    </div>
        
                </div>


            </div>
                            
        </div>
    )
}

export default ClassroomListItem