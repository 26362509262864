import React from "react";
import "./specificButton.css"
import { useState } from "react";
import { useSelector } from "react-redux";

const SpecificButton = ({img, name, specificationsState, nameEn}) => {

    const {specifications} = useSelector(state => state.filters.filters)

    const notActive = "specificButton"
    const active = "specificButton active"

    const clickButton = () => {
        specifications.includes(nameEn) ? specificationsState(nameEn, 'delete') : specificationsState(nameEn, 'add');
        console.log(specifications);
        
    }

    return (
        <div onClick={ () => clickButton()} className={specifications.includes(nameEn) ? active : notActive}>
            <div className="iconContainer">
                <img className='iconFilters' src={img} alt="" />
            </div>
            <p>{name}</p>
        </div>
    )
}

export default SpecificButton