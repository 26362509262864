import { useCallback } from "react";

export const useHttp = () => {
    const request = useCallback(async (url) => {

        console.log('hello');
        

    }, []);

    return { request }
}