import React from 'react';
import LoginPage from '../loginPage/loginPage';

function Login() {
  return (
    <>
        <LoginPage/>
    </>
  );
}

export default Login