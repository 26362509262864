import React from "react";
import "./specificInfoItem.css"


import micro from "../../images/icons/mic.svg"
import dinamic from "../../images/icons/dinamics.svg"
import projector from "../../images/icons/projector.svg"
import wifi from "../../images/icons/wifi.svg"
import pc from "../../images/icons/pc.svg"
import calendar from "../../images/other/calendar.webp"

const SpecificInfoItem = ({name, image}) => {
    console.log(image);

    const nameObj = {
        micro: [micro, "микрофон"],
        projector: [projector, "проектор"],
        wifi: [wifi, "хороший интернет"],
        dinamic: [dinamic, "колонки"],
        pc: [pc, "компьютерный класс"]
    }


    
    return(
        <li className="specific">
            <img src={nameObj[name][0]} alt="" />
            <p>{nameObj[name][1]}</p>
        </li>
    )
}

export default SpecificInfoItem