import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';


import MainPage from './components/pages/MainPage';
import InfoPage from './components/pages/InfoPage';
import Login from './components/pages/Login';


function App() {

  return (
    <Router>
        <Routes>
            <Route path='/' element={ <Login/> }/>

            <Route path="/classrooms" element={<MainPage />} />

            <Route path="/classrooms/:id" element={<InfoPage />} />

            <Route path='*' element={ <Login/> }/>
        </Routes>
    </Router>
    

  );
}

export default App;
