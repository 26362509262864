import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    entitiesAll: [],
    filteredEntities: [],
    currentEntities: [],
    entitiesloading: 'loading'
}

const entitiesSlice = createSlice({
    name: 'entities',
    initialState,
    reducers: {
        entitiesFetchedFirst: (state, action) => {
            state.entitiesAll = action.payload;
            state.filteredEntities = action.payload;
            state.currentEntities = action.payload;
            state.entitiesloading = 'idle';
        },
        entitiesFiltered: (state, action) => {
            state.filteredEntities = action.payload;
        },
        entitiesCurrent: (state, action) => {
            state.currentEntities = action.payload;
        }
    }
})

const {actions, reducer} = entitiesSlice;

export default reducer;

export const {
    entitiesFetchedFirst,
    entitiesFiltered,
    entitiesCurrent
} = actions;