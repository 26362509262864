import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={370}
    height={310}
    viewBox="0 0 370 310"
    backgroundColor="#ededed"
    foregroundColor="#c7c7c7"
    {...props}
  >
    <rect x="3" y="245" rx="14" ry="14" width="360" height="63" /> 
    <rect x="39" y="442" rx="0" ry="0" width="356" height="159" /> 
    <rect x="6" y="1" rx="14" ry="14" width="360" height="228" />
  </ContentLoader>
)

export default MyLoader

