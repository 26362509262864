import { useCallback } from "react";

export const useEndWord = () => {
    const getAuditoriumWord = useCallback((number) => {
        const lastDigit = number % 10;
        const lastTwoDigits = number % 100;
        
        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            return 'аудиторий';
        } else if (lastDigit === 1) {
            return 'аудиторию';
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            return 'аудитории';
        } else {
            return 'аудиторий';
        }
        }, [])


    const getTittleWord = useCallback((number) => {
        const lastDigit = number % 10;
        const lastTwoDigits = number % 100;
        
        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            return 'аудиторий';
        } else if (lastDigit === 1) {
            return 'аудитория';
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            return 'аудитории';
        } else {
            return 'аудиторий';
        }
        }, [])

    const getFindWord = useCallback((number) => {
        const lastDigit = number % 10;
        const lastTwoDigits = number % 100;
        
        if (lastDigit === 1) {
            return 'Найдена';
        } else {
            return 'Найдено';
        }
        }, [])

    return { getAuditoriumWord, getTittleWord, getFindWord }
}