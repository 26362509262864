import './filterButton.css'
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useDispatch } from 'react-redux';
import { entitiesCurrent } from '../../reducers/entitiesSlice';
import { useEndWord } from '../../hooks/endWord';

const FilterButtons = ({ resetFilters }) => {

    const { filteredEntities } = useSelector(state => state.entities)

    const dispatch = useDispatch();
    const { getAuditoriumWord } = useEndWord();

    const replaceCurrent =() => {
        console.log('replace');
        dispatch(entitiesCurrent(filteredEntities))
    }

    return (
        <div className="FilterContainerButtons">
            <button onClick={ () => resetFilters()} className="reset">Сбросить все</button>
            <button
                disabled={filteredEntities.length === 0}
                onClick={ () => replaceCurrent() } className="show">{filteredEntities.length ? `Показать ${filteredEntities.length} ${getAuditoriumWord(filteredEntities.length)}` : `Нет подходящих аудиторий`}</button>
        </div>
    )
}

export default FilterButtons;