const dataObj = {
    "classrooms": [
      {
        "id": "1",
        "name": "Аудитория №3412",
        "square": 100,
        "seats": 70,
        "available_dates": ["08", "09", "10", "11"],
        "available_times": ["12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
        "specifications": ["projector", "micro", "wifi", "dinamic"],
        "images": [
          "https://fortestsomesites.site/px-conversions/1.webp",
          "https://kartinki.pics/uploads/posts/2022-12/1671724996_kartinkin-net-p-auditoriya-kartinki-krasivo-8.jpg"
        ]
      },
      {
        "id": "2",
        "name": "Аудитория №1234",
        "square": 55,
        "seats": 90,
        "available_dates": ["08", "09", "10", "11"],
        "available_times": ["12:00", "13:00", "14:00"],
        "specifications": ["wifi", "dinamic", "pc"],
        "images": [
            "https://fortestsomesites.site/px-conversions/3.webp",
            "https://fortestsomesites.site/px-conversions/2.webp",
            "https://fortestsomesites.site/px-conversions/4.webp"
        ]
      },
      {
        "id": "3",
        "name": "Аудитория №5678",
        "square": 75,
        "seats": 50,
        "available_dates": ["08", "09", "10", "11"],
        "available_times": ["12:00", "13:00", "14:00"],
        "specifications": ["micro", "pc"],
        "images": [
          "https://fortestsomesites.site/px-conversions/5.webp",
          "https://fortestsomesites.site/px-conversions/6.webp",
            "https://fortestsomesites.site/px-conversions/7.webp",
        ]
      },
      {
        "id": "4",
        "name": "Аудитория №9999",
        "square": 120,
        "seats": 80,
        "available_dates": ["08", "09", "10", "11"],
        "available_times": ["12:00", "13:00", "14:00"],
        "specifications": ["projector", "wifi", "dinamic"],
        "images": [ 
          "https://fortestsomesites.site/px-conversions/8.webp",
          "https://fortestsomesites.site/px-conversions/9.webp"
        ]
      },
      {
        "id": "5",
        "name": "Аудитория №7777",
        "square": 90,
        "seats": 60,
        "available_dates": ["08", "09", "10", "11"],
        "available_times": ["12:00", "13:00", "14:00"],
        "specifications": ["projector", "micro", "pc"],
        "images": [
          "https://fortestsomesites.site/px-conversions/10.webp",
          "https://fortestsomesites.site/px-conversions/11.webp",
        ]
      },
      {
        "id": "6",
        "name": "Аудитория №5555",
        "square": 110,
        "seats": 75,
        "available_dates": ["08", "09", "10", "11"],
        "available_times": ["12:00", "13:00", "14:00"],
        "specifications": ["wifi", "dinamic"],
        "images": [
          "https://fortestsomesites.site/px-conversions/12.webp",
          "https://fortestsomesites.site/px-conversions/13.webp",
        ]
      },
      {
        "id": "7",
        "name": "Аудитория №4444",
        "square": 80,
        "seats": 100,
        "available_dates": ["08", "09", "10", "11"],
        "available_times": ["12:00", "13:00", "14:00", "15:00", "16:00"],
        "specifications": ["projector", "wifi", "pc", "micro"],
        "images": [
          "https://fortestsomesites.site/px-conversions/14.webp",
          "https://fortestsomesites.site/px-conversions/15.webp",
          "https://fortestsomesites.site/px-conversions/17.webp",
        ]
      },
      {
        "id": "8",
        "name": "Аудитория №3333",
        "square": 60,
        "seats": 35,
        "available_dates": ["08", "09", "10", "11"],
        "available_times": ["12:00", "13:00", "14:00"],
        "specifications": ["micro", "dinamic"],
        "images": [
          "https://fortestsomesites.site/px-conversions/18.webp",
          "https://fortestsomesites.site/px-conversions/19.webp",
        ]
      },
      {
        "id": "9",
        "name": "Аудитория №2222",
        "square": 70,
        "seats": 45,
        "available_dates": ["08", "09", "10", "11"],
        "available_times": ["12:00", "13:00", "14:00"],
        "specifications": ["projector", "pc"],
        "images": [
          "https://fortestsomesites.site/px-conversions/20.webp",
          "https://fortestsomesites.site/px-conversions/21.webp",
          ]
      },
      {
        "id": "10",
        "name": "Аудитория №1111",
        "square": 85,
        "seats": 55,
        "available_dates": ["08", "09", "10", "11"],
        "available_times": ["12:00", "13:00", "14:00"],
        "specifications": ["wifi", "dinamic", "micro"],
        "images": [
          "https://fortestsomesites.site/px-conversions/22.webp",
          "https://fortestsomesites.site/px-conversions/23.webp",
          ]
      },
      {
        "id": "11",
        "name": "Аудитория №999",
        "square": 95,
        "seats": 165,
        "available_dates": ["08", "09", "10", "11"],
        "available_times": ["12:00", "13:00", "14:00"],
        "specifications": ["projector", "dinamic", "pc"],
        "images": [
          "https://fortestsomesites.site/px-conversions/24.webp",
          "https://fortestsomesites.site/px-conversions/25.webp",
          ]
      },
      {
        "id": "12",
        "name": "Аудитория №888",
        "square": 75,
        "seats": 140,
        "available_dates": ["08", "09", "10", "11"],
        "available_times": ["12:00", "13:00", "14:00"],
        "specifications": ["wifi", "pc"],
        "images": [
          "https://fortestsomesites.site/px-conversions/26.webp",
          "https://fortestsomesites.site/px-conversions/27.webp"
          ]
      },
      {
        "id": "13",
        "name": "Аудитория №777",
        "square": 110,
        "seats": 620,
        "available_dates": ["08", "09", "10", "11"],
        "available_times": ["12:00", "13:00", "14:00"],
        "specifications": ["micro", "dinamic"],
        "images": [
          "https://fortestsomesites.site/px-conversions/28.webp",
          "https://fortestsomesites.site/px-conversions/29.webp",
          ]
      },
      {
        "id": "14",
        "name": "Аудитория №666",
        "square": 150,
        "seats": 45,
        "available_dates": ["08", "09", "10", "11"],
        "available_times": ["12:00", "13:00", "14:00"],
        "specifications": ["projector", "wifi", "pc"],
        "images": [
          "https://fortestsomesites.site/px-conversions/30.webp",
          "https://fortestsomesites.site/px-conversions/31.webp",
          ]
    },
    {
        "id": "15",
        "name": "Аудитория №6266",
        "square": 16,
        "seats": 15,
        "available_dates": ["08", "09", "10", "11"],
        "available_times": ["12:00", "13:00", "14:00"],
        "specifications": ["pc"],
        "images": [
          "https://fortestsomesites.site/px-conversions/15.webp",
          "https://fortestsomesites.site/px-conversions/17.webp",
          ]
    }
]
}

export default dataObj;