import './Filter.css'
import React, { useState, useEffect } from 'react';

import Slider from 'react-slider'
import FilterButtons from '../filterButtons/filterButtons'
import { filtersPut } from '../../reducers/filtersSlice';
import { entitiesFiltered } from '../../reducers/entitiesSlice';
import { useDispatch, useSelector } from 'react-redux';
import SpecificationList from '../SpecificationList/SpecificationList'


const FilterContainer = () => {
    // need to fix the slider, need remove it in seperate component to render optimization 

    const min_sq = 10;
    const max_sq = 150;

    const min_person = 1;
    const max_person = 150;

    const [squareValues, setSquareValues] = useState([min_sq, max_sq]);
    const [values_person, setValuesPerson] = useState([min_person]);
    const [specificationsArr, setSpecificationsArr] = useState([]);

    const allEntities = useSelector(state => state.entities.entitiesAll)
    const dispatch = useDispatch();

    const resetFilters = () => {
        console.log('reset');
        setSquareValues([min_sq, max_sq])
        setValuesPerson([min_person])
        setSpecificationsArr([])
    }


    const setSpecifications = (item, str) => {
        
        if (str === 'add') {
            setSpecificationsArr( () => [...specificationsArr, item]) 
        } else if (str === 'delete') {
            setSpecificationsArr( () => specificationsArr.filter(element => element !== item));
        }        
    }

    useEffect(() => {
        updateButtonDate()
    }, [specificationsArr])

    const filtering = ({min_sq, max_sq, specifications, seats}) => {
        return allEntities.filter(item => (
            (item.seats >= seats) && 
            (specifications.every(specific => item.specifications.includes(specific))) &&
            (item.square >= min_sq && item.square <= max_sq)
        ))
    }

    const updateButtonDate = () => {

        const filterObj = {
            min_sq: squareValues[0],
            max_sq: squareValues[1],
            specifications: specificationsArr,
            seats: values_person
        }
        console.log(filtering(filterObj))
        dispatch(filtersPut(filterObj))
        dispatch(entitiesFiltered(filtering(filterObj)))
    }

    const removeActiveButtons = () => {

    }
    
    
    const changeInputsValues = (e, num) => {
        switch(num) {
            case 'first_square':
                if (!isNaN(e.target.value))
                setSquareValues([+(e.target.value), squareValues[1]])
                updateButtonDate()
                break;

            case 'second_square':
                if (!isNaN(e.target.value))
                setSquareValues([squareValues[0], +(e.target.value)])
                updateButtonDate()
                break;

            case 'person_count':
                if (!isNaN(e.target.value))
                setValuesPerson(+(e.target.value))
                updateButtonDate()
                break;  
            
            case 'onAfterChangeFirst':
                updateButtonDate()
                break;
            
            default:
                console.log('default');
        }
        
    }
    return (
        <div className="wholeContainerFilters">
            <div className="toPaddingSettings">
            <div className="setSquare">
                <h3>Площадь</h3>
                <Slider 
                    className={"slider"}
                    onChange={setSquareValues}
                    onAfterChange={ e => changeInputsValues(e, 'onAfterChangeFirst')}
                    value={squareValues}
                    pearling={true}
                    thumbActiveClassName={'activeThumb'}
                    trackClassName={'trackBetween'}
                    minDistance={5}
                    min={min_sq}
                    max={max_sq}
                    />

                <div className="manualInputs">
                    <div className="firstInputRange InputRange">
                        <p>от</p>
                        <input type="text" 
                        value={squareValues[0]}
                        onChange={ (e) => {changeInputsValues(e, 'first_square')}}/>
                    </div>
                    <div className="betweenLine"></div>
                    <div className="secondInputRange InputRange">
                        <p>до</p>
                        <input type="text" value={squareValues[1]}
                        onChange={ (e) => {changeInputsValues(e, 'second_square')}}/>
                    </div>
                </div>
            </div>


            <div className="specifications">
                <h3>Технические характеристики</h3>
                <SpecificationList specificationsState={setSpecifications}/>

            </div>


            <div className="setPeopleCount">
            <h3>Количество мест</h3>
                <Slider 
                    className={"slider"}
                    onChange={setValuesPerson}
                    onAfterChange={ e => changeInputsValues(e, 'onAfterChangeFirst')}
                    value={values_person}
                    thumbActiveClassName={'activeThumb'}
                    trackClassName={'trackBetween'}
                    min={min_person}
                    max={max_person}
                    />

                    <div className="secondInputRange InputRange">
                        <p>до</p>
                        <input type="text" value={`${values_person}`}
                        onChange={ (e) => {changeInputsValues(e, 'person_count')}}/>
                    </div>
            </div>

            </div>

            <div className="line"></div>

            <div className="FilterButtonsContainer">
                <FilterButtons resetFilters={ resetFilters }/>
            </div>

            <div className="line secondLine"></div>
            


        </div>
    )
}

export default FilterContainer;