import './SpecificationList.css';
import React from 'react';
import SpecificButton from '../specificButton/specificButton';

import micro from "../../images/icons/mic.svg"
import dinamic from "../../images/icons/dinamics.svg"
import projector from "../../images/icons/projector.svg"
import wifi from "../../images/icons/wifi.svg"
import pc from "../../images/icons/pc.svg"


const SpecificationList = ({specificationsState}) => {
    
    const buttonsArr = [
        {
        name: "микрофон",
        nameEn: "micro",
        img : micro
        },
        {
        name: "проектор",
        nameEn: "projector",
        img : projector
        },
        {
        name: "хороший интернет",
        nameEn: "wifi",
        img : wifi
        },
        {
        name: "колонки",
        nameEn: "dinamic",
        img : dinamic
        },
        {
        name: "компьютерный класс",
        nameEn: "pc",
        img : pc
        }
    ]
    return (
        <div className="specificationButtons">
            
            {buttonsArr.map((item, index) => {
                return <SpecificButton 
                            key={index} 
                            nameEn={item.nameEn} 
                            specificationsState={specificationsState} 
                            img={item.img} 
                            name={item.name}
                            />
            })}
            
        </div>
    )
}

export default SpecificationList;