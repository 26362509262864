import React from "react";
import "./dateButtonsItem.css"


const DateButtonsItem = ({date, index, datePicked, setDatePicked}) => {
    const daysOfWeekNames = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

    const pickAction = () => {
        if (!datePicked.includes(date)) {
            setDatePicked([date]);
        } else {
            setDatePicked(datePicked.filter(item => item !== date));
        }
    }
    const defaultClass = datePicked.includes(date) ? "dateShape active" : "dateShape"
    return (
        <li onClick={ () => pickAction() } className={defaultClass}>
            <p>{daysOfWeekNames[index]}</p>
            <h5>{date}</h5>
        </li>
    )
}

export default DateButtonsItem