import React from "react"
import "./popUp.css"
import { useEffect, useRef, useMemo, useState } from "react"
import closeImg from "../../images/icons/close.svg"
import { Checkmark } from 'react-checkmark'

const PopUp = ({popUpShow, setPopUpShow, datePicked, timePicked, setDatePicked, setTimePicked}) => {

    const infoContainer = useRef(null)
    const wholePopUp = useRef(null)

    const [orderNum, setOrderNum] = useState()

    const closePop = (e) => {
        console.log(e);
        if (e.target.className !== "modal") {
            setDatePicked([])
            setTimePicked([])
            setPopUpShow(false)
        }
    }

    const memoizedValue = useMemo(() => {
        return Math.floor(Math.random() * 9000) + 1000
      }, [orderNum]);

    
    useEffect(() => {
        document.body.classList.add('modal-open');

        const timer = setTimeout(() => {
            infoContainer.current.classList.add('showContainer');
          }, 700);

        const secTimer = setTimeout(() => {
            wholePopUp.current.classList.add('popUpShow');
        }, 0)

        return () => {
            document.body.classList.remove('modal-open');
            // wholePopUp.current.classList.remove('popUpShow');
            clearTimeout(timer)
            clearTimeout(secTimer)
          };
    }, [popUpShow])

    return (
        <div onClick={ (e) => closePop(e) } className="popUp" ref={wholePopUp}>
            <div className="modal">
                <div onClick={ () => setPopUpShow(false) } className="closeButton">
                    <img src={closeImg} className="svg-close" alt="" />
                </div>

                <div className="checkmarkContainer">
                    <Checkmark size='100px' color='#3c3c3c'/>
                </div>

                <div ref={infoContainer} className="secondOrderShow">
                    <h2>Бронирование произведено <br/> успешно!</h2>
                    <p className="dateComplete">{`📅 ${datePicked[0]} декабря, ${timePicked.join("-")}`}</p>

                    <p className="statusText">Ваш номер заявки <span className="underLine">{`#${memoizedValue}`}</span> , <br/> отслеживание <span className="spanHere">тут</span>.</p>
                </div>

            </div>
        </div>
    )
}

export default PopUp
