import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';



import './loginPage.css'
import miitLogo from "../../images/other/logo-miit.svg"
import hidden from "../../images/icons/hidden_eyes.svg"
import not_hidden from "../../images/icons/not_hidden.svg"


function LoginPage() {
    const [isHide, setIsHide] = useState(true)
    const [credentials, setCredentials] = useState({ username: '', password: '' });

    const [loginState, setLoginState] = useState(false)
    const [isPasswordCorrect, setIsPasswordCorrect] = useState(true)


    useEffect(() => {   
        localStorage.setItem("login", false)
    }, [])

    const logInClick = () => {
        if (credentials.password.replace(/\s/g, '') === 'test' && credentials.username.replace(/\s/g, '') === 'test') {
            localStorage.setItem("login", true);
            console.log(localStorage.getItem("login"));
            setLoginState(true)
        } else {
            setIsPasswordCorrect(false)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        
        setCredentials((prevCredentials) => ({
          ...prevCredentials,
          [name]: value,
        }));
      };

  return (
    <>

        {loginState && (
            <Navigate to="/classrooms" replace={true} />
        )}
        <div className="wholePageLogin">
            <div className="leftPage">
                <img className='miitLogoLogin' src={miitLogo} alt="" />
                <div className="listLogin">
                </div>
            </div>
            <div className="rightPage">
                <div className="login_container">
                    <h2>Добро пожаловать!</h2>
                    <p className='login_p'>Войдите, используя ваш логин и пароль.</p>
                    <input 
                        value={credentials.user}
                        onChange={ (e) => handleChange(e) }
                        name='username'
                        className="loginName"
                        type="text" 
                        placeholder="Логин"/>

                    <div className="password-container">
                        <input 
                            name='password'
                            value={credentials.password}
                            onChange={ (e) => handleChange(e)}
                            type={isHide ? 'password' : 'text'} 
                            className="passwordName" placeholder="Пароль"/>
                        <button onClick={() => setIsHide(!isHide)} id="togglePassword"><img src={isHide ? hidden : not_hidden} alt="" /></button>
                    </div>

                    {!isPasswordCorrect && (
                        <p className='passNotCorr'>Неверный логин или пароль</p>
                    )}

                    <div className="remember-me">
                        <input type="checkbox" id="remember-checkbox"/>
                        <label htmlFor="remember-checkbox">Запомнить меня</label>
                    </div>

                    <div onClick={() => logInClick()} className="loginButton">Войти</div>
                </div>
            </div>
        </div>
    </>
    );
    }

export default LoginPage

