import React from "react";
import "./timeButtonsItem.css"


const TimeButtonsItem = ({time, timePicked, setTimePicked}) => {
    const pickAction = () => {
        if (!timePicked.includes(time)) {
            setTimePicked([...timePicked, time]);
        } else {
            setTimePicked(timePicked.filter(item => item !== time));
        }
    }
    
    const defaultClass = timePicked.includes(time) ? "timeShape active" : "timeShape"
    return(
        <li onClick={ () => pickAction()} className={defaultClass}>
            <h5>{time}</h5>
        </li>
    )
}

export default TimeButtonsItem