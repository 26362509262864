import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    filters : {
    square_min : 0,
    square_max : 0,
    seats : 0,
    specifications: []
}}

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        filtersPut: (state, action) => {state.filters = action.payload},
    }
})

const {actions, reducer} = filtersSlice;

export default reducer;

export const {
    filtersPut
} = actions;