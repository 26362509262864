import { configureStore } from '@reduxjs/toolkit';

import filters from "../reducers/filtersSlice"
import entities from "../reducers/entitiesSlice"

const store = configureStore({
    reducer: {filters, entities},
    devTools: process.env.NODE_ENV !== 'production'
    });

export default store;