import './classroomList.css'
import React from 'react';
import { useEffect } from 'react';
import { useHttp } from '../../hooks/http.hook';
import { useEndWord } from '../../hooks/endWord';
import { useDispatch, useSelector } from 'react-redux';

import ClassroomListItem from '../classroomListItem/classroomListItem'
import ContentLoader from '../loader/loader'
import dataObj from './data';
import { entitiesFetchedFirst } from '../../reducers/entitiesSlice';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

const ClassroomList  = () => {

    const { request } = useHttp();
    const { getTittleWord, getFindWord } = useEndWord();
    const dispatch = useDispatch();
    const { currentEntities } = useSelector(state => state.entities);

    const data = dataObj.classrooms;
    useEffect(() => {

        dispatch(entitiesFetchedFirst(data));                   

    }, [])

    const renderItems = (arr) => {
      if (arr.length === 0) {
        return (
          <div className="roomContainer"><ContentLoader/><ContentLoader/><ContentLoader/><ContentLoader/><ContentLoader/><ContentLoader/></div>
        )
      }
      return (
        <TransitionGroup className="allRooms">
          {arr.map((item) => (
            <CSSTransition
              key={item.id}
              timeout={100}
              classNames="item">
            
              <ClassroomListItem  
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  square={item.square}
                  seats={item.seats}
                  images={item.images}/>
            

            </CSSTransition>
          ))}
        </TransitionGroup>
      )
    }


    const elements = renderItems(currentEntities)
    return (
        <div className="roomContainer">
            <h2>{`${getFindWord(currentEntities.length)} ${currentEntities.length} ${getTittleWord(currentEntities.length)}`}</h2>

                {elements}

        </div>
    )
}

export default ClassroomList