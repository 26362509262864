import React, { useEffect } from 'react';

import { useNavigate } from "react-router-dom";

import ClassPageInfo from '../classPageInfo/classPageInfo.js'


function InfoPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if(localStorage.getItem("login" === "false")) {
      navigate('/')
    }
    
  }, [])

  return  (
    <>
      <ClassPageInfo/>
    </>
  )

  }
  
  export default InfoPage